import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

type initialStateType = {
    isPausedByUser: boolean; // when ever user changes the state by clickiing play and pause on wave icon
    isAutoPaused: boolean; // whenever user plays video then this should be updated,
    isFirstInteraction: boolean;
};

const initialState: initialStateType = { isPausedByUser: false, isAutoPaused: false, isFirstInteraction: false };

const audioSlice = createSlice({
    name: "audio",
    initialState: initialState,
    reducers: {
        playByUser: (state) => {
            return { ...state, isPausedByUser: false };
        },
        pauseByUser: (state) => {
            return { ...state, isPausedByUser: true };
        },
        autoPlay: (state) => {
            return { ...state, isAutoPaused: false };
        },
        autoPause: (state) => {
            return { ...state, isAutoPaused: true };
        },
        onFirstInteraction: (state) => {
            return { ...state, isFirstInteraction: true };
        }
    }
});

export const audioAction = audioSlice.actions;
export const audioSelector = (state: RootState) => state.audioReducer;
export const audioReducer = audioSlice.reducer;