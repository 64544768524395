import MenuItems from '../../helper/MenuItems';
import Styles from './about.module.scss';
import { WebsiteConfigsData } from '../../redux/constants/dashboard_objects';

const About = ({ id, data }: { data?: WebsiteConfigsData | undefined; id: string }) => {
	return (
		<div id={id} className={Styles.main_body}>
			<img className={Styles.main_body_background} src="./images/About.png" />
			<div className={Styles.custom_container}>
				<div className={Styles.about_container}>
					<div className={Styles.about_container_content_1}>about.</div>
					<div className={Styles.about_container_content_2}>
						<span dangerouslySetInnerHTML={{ __html: data?.about_text ?? '' }}></span>
						<div className={Styles.about_btn}>
							<a href="mailto:bhise@themapmakerfrombaghdad.com">WRITE TO US</a>
							<a
								href="https://cdn.themapmakerfrombaghdad.com/s/Z1HGjQM"
								target="_blank"
								rel="noreferrer"
							>
								PROJECT REPORT
							</a>
							<a
								href="https://cdn.themapmakerfrombaghdad.com/s/Njmbtqj"
								target="_blank"
								rel="noreferrer"
							>
								RESOURCES
							</a>
						</div>
					</div>
					<div className={Styles.about_container_content_3}>
						<div className={Styles.authors_entry}>
							<div className={Styles.authors_names}>
								This Foundation Project is implemented by India Foundation for the Arts
								(IFA) under the Arts Research programme, made possible with support from
								BNP Paribas India.
							</div>
						</div>
						<div className={Styles.authors_main_heading}>
							Project Conceptualised by <span>Kaddukkas</span>
						</div>
						<div className={Styles.authors_details}>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>
									Writing and Conceptualisation
								</div>
								<div className={Styles.authors_names}>Anuj Malhotra, Gaurav Puri</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>Web Development</div>
								<div className={Styles.authors_names}>
									Orendra Singh, Vinayak Agarwal
								</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>Film and Media Production</div>
								<div className={Styles.authors_names}>
									Divya Sachar, Hemanth Raju, Suchana, Vedika Kruti,<br></br> Ketan
									Dua,Mahesh S, Gaurav Puri, Anuj Malhotra
								</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>Web Design and Layouts</div>
								<div className={Styles.authors_names}>Anuj Malhotra</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>Sound Design</div>
								<div className={Styles.authors_names}>Ketan Dua</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>
									Copyright for the 8mm, prosumer and homemade footage featured on the
									website belongs to{' '}
									<span className={Styles.authors_names}>Ashish Avikunthak</span>
								</div>
							</div>
							<div className={Styles.authors_entry}>
								<div className={Styles.authors_heading}>Special Acknowledgments</div>
								<div className={Styles.authors_names}>
									Ashish Avikunthak, Suryasarathi Bhattacharjee,<br></br>Veena
									Malhotra, Chandan Sen, Pooja Chopra
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={Styles.menu_bar}>
					{/* <img src="" className={Styles.logo} /> */}
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div className={Styles.vertical_line}></div>
				{/* <div className={Styles.social_media_icons}>
					<a href="">
						<img src="./images/twitter.png" alt="twitter logo" />
					</a>
					<a href="">
						<img src="./images/facebook.png" alt="facebook logo" />
					</a>
					<a href="">
						<img src="./images/instagram.png" alt="instagram" />
					</a>
				</div> */}
			</div>
		</div>
	);
};
export default About;
