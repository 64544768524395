// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/archive.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archive_bgg__xVXMF {
  --background: "";
}

.archive_bg__QHO3F {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-image: var(--background);
  background-size: cover;
  width: 1280px;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.archive_headline__orsFN {
  font-family: Larken;
  color: rgb(255, 255, 255);
  font-size: 88px;
  margin-top: -43px;
  margin-bottom: 10px;
  margin-left: 11px;
}

.archive_text__t01rM {
  font-family: ESB;
  color: rgb(255, 255, 255);
  font-size: 11px;
  font-weight: bold;
  margin-top: -18px;
  letter-spacing: 0.3px;
}`, "",{"version":3,"sources":["webpack://./src/componenets/archive/archive.module.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;AACD;;AAEA;EACC,yDAAA;EACA,mCAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACD;;AAEA;EACC,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AACD;;AAEA;EACC,gBAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;AACD","sourcesContent":[".bgg {\n\t--background: '';\n}\n\n.bg {\n\tbackground-image: url('/images/archive.gif');\n\tbackground-image: var(--background);\n\tbackground-size: cover;\n\twidth: 1280px;\n\theight: 800px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tflex-direction: column;\n}\n\n.headline {\n\tfont-family: Larken;\n\tcolor: rgb(255, 255, 255);\n\tfont-size: 88px;\n\tmargin-top: -43px;\n\tmargin-bottom: 10px;\n\tmargin-left: 11px;\n}\n\n.text {\n\tfont-family: ESB;\n\tcolor: rgb(255, 255, 255);\n\tfont-size: 11px;\n\tfont-weight: bold;\n\tmargin-top: -18px;\n\tletter-spacing: 0.3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgg": `archive_bgg__xVXMF`,
	"bg": `archive_bg__QHO3F`,
	"headline": `archive_headline__orsFN`,
	"text": `archive_text__t01rM`
};
export default ___CSS_LOADER_EXPORT___;
