import { useSelector } from 'react-redux';
import {
	authAction,
	AuthPage,
	authSelector,
	UserType
} from '../../redux/reducer/auth.reducer';
import Styles from './UserPage.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store';
import PointsClicked, { PointsClickedData } from '../tooltip/PointsClicked';
import MenuItems from '../../helper/MenuItems';
import { WebsiteConfigsData } from '../../redux/constants/dashboard_objects';
import { useSearchParams } from 'react-router-dom';
import { ConfirmationComponent, WhySignUp } from './common/login_message/LoginMessage';
import LoginComponent from './LoginComponent';
import ResetPasswordComponent from './ResetPasswordComponent';
import SignUpComponent from './SignUpComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import LoggedIn from './logged_in/LoggedIn';

const UserPage = ({ id, data }: { data: WebsiteConfigsData; id: string }) => {
	const { user, page } = useSelector(authSelector);
	const [searchParams] = useSearchParams();

	const dispatch = useAppDispatch();

	const code = searchParams?.get('code') ?? "";
	useEffect(() => {
		if (code) {
			dispatch(authAction.update_state(AuthPage.RESET_PASSWORD));
		}
	}, []);

	const [showMedia, setShowMedia] = useState<{
		id: number;
		playlist: PointsClickedData;
		unique: string;
	} | null>(null);

	const onHomePage = () => {
		document
			.getElementById('homepage')
			?.scrollIntoView({ behavior: 'smooth' })
	}


	return (
		<div id={id} className={Styles.fiction}>
			<img
				src={
					(process.env.REACT_APP_BASE_URL ?? '') + data?.user_bg?.data?.attributes?.url ||
					'./images/user_bg.png'
				}
			></img>
			<div className={Styles.front}>
				<div className={Styles.grid_1}>
					<div className={Styles.menu_bar}>
						<img
							src={'./images/logo.png'}
							className={Styles.logo}
							onClick={onHomePage}
						/>
						<div className={Styles.menu_items}>
							<MenuItems />
						</div>
					</div>
				</div>
				<div className={Styles.grid_2}>
					<UserLogin setShowMedia={setShowMedia} code={code} user={user} state={page} onHomePage={onHomePage} />
				</div>
			</div>
			{showMedia && user && (
				<PointsClicked
					data={showMedia?.playlist}
					id={showMedia?.unique}
					onClose={() => setShowMedia(null)}
				/>
			)}
		</div>
	);
};

const UserLogin = ({ setShowMedia, user, state, code, onHomePage }: { setShowMedia: (user: { id: number; playlist: PointsClickedData; unique: string; }) => void,user: UserType, state: AuthPage, code: string, onHomePage: () => void }) => {
	const [email, setEmail] = useState('');

	if (user) {
		return <LoggedIn setShowMedia={setShowMedia} user={user} onHomePage={onHomePage} />
	}

	switch (state) {
		case AuthPage.LOGIN:
			return <LoginComponent email={email} setEmail={setEmail} />
		case AuthPage.SIGNUP:
			return <SignUpComponent email={email} setEmail={setEmail} />
		case AuthPage.RESET_PASSWORD:
			return <ResetPasswordComponent code={code} />
		case AuthPage.FORGOT_PASSWORD:
			return <ForgotPasswordComponent email={email} setEmail={setEmail} />
		case AuthPage.EMAIL_NOT_CONFIRMED:
			return <ConfirmationComponent />
		default:
			return <WhySignUp />
	}
};

export default UserPage;
