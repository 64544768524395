import { useEffect, useState, useRef, useMemo } from 'react';
import styles from './Video_player.module.scss';
import ShareIcons from '../../helper/Share';
import { MapVideo } from '../../redux/constants/dashboard_objects';
import { audioAction } from '../../redux/reducer/audio.reducer';
import { useAppDispatch } from '../../store';

const VideoOverlay = ({
	noBg,
	video,
	onClose,
	add,
	loading,
	added,
	disableDetails = false
}: {
	noBg?: boolean;
	disableDetails?: boolean;
	video: MapVideo;
	onClose: () => void;
	add: () => void;
	loading: boolean;
	added: boolean;
}) => {
	const [showVideo, setShowVideo] = useState(false);
	const [play, setPlay] = useState(false);
	const [mouseMove, setMouseMove] = useState(true);
	const [showOverlay, setShowOverlay] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const dispatch = useAppDispatch();


	const handlePlayPause = () => {
		if (videoRef?.current == null) return;

		if (isPlaying) {
			videoRef.current.pause();
			setIsPlaying(false);
		} else {
			dispatch(audioAction.autoPause());
			videoRef.current.play();
			setIsPlaying(true);
		}
	};

	useEffect(() => {
		if (noBg) {
			setShowVideo(true);
			setPlay(true);
		}
	}, []);

	let timeout: NodeJS.Timeout;

	const onMouseMove = () => {
		clearTimeout(timeout);
		setMouseMove(true);
		timeout = setTimeout(function () {
			setMouseMove(false);
		}, 3500); // 3.5 seconds
	};

	const shareUrl = useMemo(() => {
		switch (video.video_type) {
			case 'KHURSHEED':
				return `I discovered Letters From Khursheed, Number Four, entitled, ${video.video_title} from The Mapmaker from Baghdad here: ${window.location.href}`;
			case 'INTERVIEW':
				return `Oral testimonies abound within a city kept alive by its witnesses. ‘An ${video.video_title}’ from ‘The Mapmaker from Baghdad’ is here: ${window.location.href}`;
			case 'INTERLUDE':
				return `An interlude, a moment of pause, a site of contemplation from The Mapmaker from Baghdad here: ${window.location.href} `;
			case 'EPISODE':
				return `On a fateful night in 1979 Bombay, Rafique Baghdadi imprinted the map of his escape route onto the city. The chronicles of his adventure now survive with us. I discovered a part of Rafique's journey when I saw, ${video.video_title} from ‘The Mapmaker from Baghdad’ here: ${window.location.href}`;
			case 'PROLOGUE':
				return `It is here that our account of Rafique’s dreaded night begins; the prologue to a haunting, ‘The Thousand Clones of S Bhise’, from ‘The Mapmaker from Baghdad’, is available here: ${window.location.href}`;
			case 'EPILOGUE':
				return `…in the womb of this metabolic crowd, Rafique begun to grow a new body, to discover a new voice. His decade had reached its epilogue. ‘The Sky is their House’ from ‘The Mapmaker from Baghdad’, is available here: ${window.location.href}`;
		}
		return '';
	}, []);

	return (
		<div className={styles.video_overlay}>
			{noBg ? (
				showOverlay ? (
					<div
						className={styles.video_overlay_container + ' ' + styles.nobg}
						style={{
							zIndex: 30,
							background: noBg
								? ''
								: `url(${(process.env.REACT_APP_BASE_URL ?? '') + video?.video_thumbnail?.data?.attributes?.url})`
						}}
					>
						<div className={styles.video_top_nav_bar}>
							<div className={styles.video_interaction_btns}>
								<div
									className={styles.video_play_btn}
									onClick={() => {
										setShowVideo(true);
										handlePlayPause();
									}}
								>
									<img src="/images/playbtn2.png" alt="" />
									<span>PLAY</span>
								</div>
								<div className={styles.video_play_btn} onClick={() => add()}>
									{loading ? (
										<img
											style={{ height: '20px', width: '32px' }}
											className={styles.loadingBar}
											src="./images/loadingBar.gif"
											alt="Remove from Playlist"
										/>
									) : added ? (
										<img
											style={{ transform: 'rotate(180deg)' }}
											src="./images/playlist.png"
											alt="Remove from playlist"
										/>
									) : (
										<img src="./images/playlist.png" alt="" />
									)}

									<span>
										{loading
											? 'LOADING'
											: added
												? 'REMOVE FROM PLAYLIST'
												: 'ADD TO PLAYLIST'}
									</span>
								</div>
							</div>
							<div
								className={styles.video_close_btn}
								onClick={() => {
									onClose();
									dispatch(audioAction.autoPlay());
								}}
							>
								X
							</div>
						</div>
						{disableDetails ? (
							<></>
						) : (
							<div className={styles.video_information}>
								<div className={styles.video_caption}>{video?.video_caption}</div>
								<div className={styles.video_title}>{video?.video_title}</div>
								<div className={styles.video_prepared_by}>
									<span>Prepared By</span> {video?.video_author}
								</div>
							</div>
						)}
						<div className={styles.video_social_media_icons}>
							<ShareIcons text={shareUrl} />
						</div>
					</div>
				) : (
					<></>
				)
			) : (
				<div
					className={styles.video_overlay_container}
					style={{
						background: noBg
							? ''
							: `url(${(process.env.REACT_APP_BASE_URL ?? '') + video?.video_thumbnail?.data?.attributes?.url})`
					}}
				>
					<div className={styles.video_top_nav_bar}>
						<div className={styles.video_interaction_btns}>
							<div className={styles.video_play_btn} onClick={() => setShowVideo(true)}>
								<img src="/images/playbtn2.png" alt="" />
								<span>PLAY</span>
							</div>
							<div className={styles.video_play_btn} onClick={() => add()}>
								{loading ? (
									<img
										style={{ height: '20px', width: '32px' }}
										className={styles.loadingBar}
										src="./images/loadingBar.gif"
										alt="Remove from Playlist"
									/>
								) : added ? (
									<img
										style={{ transform: 'rotate(180deg)' }}
										src="./images/playlist.png"
										alt="Remove from playlist"
									/>
								) : (
									<img src="./images/playlist.png" alt="" />
								)}

								<span>
									{loading
										? 'LOADING'
										: added
											? 'REMOVE FROM PLAYLIST'
											: 'ADD TO PLAYLIST'}
								</span>
							</div>
						</div>
						<div className={styles.video_close_btn} onClick={onClose}>
							X
						</div>
					</div>
					{disableDetails ? (
						<></>
					) : (
						<div className={styles.video_information}>
							<div className={styles.video_caption}>{video?.video_caption}</div>
							<div className={styles.video_title}>{video?.video_title}</div>
							<div className={styles.video_prepared_by}>
								<span>Prepared By</span> {video?.video_author}
							</div>
						</div>
					)}
					<div className={styles.video_social_media_icons}>
						<ShareIcons text={shareUrl} />
					</div>
				</div>
			)}
			{noBg
				? showVideo && (
						<div className={styles.video_player} onMouseMove={onMouseMove}>
							{showOverlay ? (
								<></>
							) : (
								<div
									className={styles.video_close_btn}
									style={{ opacity: mouseMove ? '1' : '0' }}
									onClick={() => {
										onClose();
										dispatch(audioAction.autoPlay());
									}}
								>
									X
								</div>
							)}
							<video
								onClick={() => handlePlayPause()}
								ref={videoRef}
								onContextMenu={(e) => e.preventDefault()}
								onPlay={() => {
									setPlay(true);
									setShowOverlay(false);
									dispatch(audioAction.autoPause());
								}}
								onPause={() => {
									setShowOverlay(true);
								}}
								autoPlay
								muted={play ? false : true}
								controls
								controlsList="nofullscreen nodownload"
								disablePictureInPicture
								src={update_video_url(video.video_url)}
							></video>
						</div>
					)
				: showVideo && (
						<div className={styles.video_player} onMouseMove={onMouseMove}>
							<div
								className={styles.video_close_btn}
								style={{ opacity: mouseMove ? '1' : '0' }}
								onClick={() => {
									setShowVideo(false);
									dispatch(audioAction.autoPlay());
								}}
							>
								X
							</div>
							<video
								onContextMenu={(e) => e.preventDefault()}
								onPlay={() => {
									setPlay(true);
									dispatch(audioAction.autoPause());
								}}
								autoPlay
								muted={play ? false : true}
								controls
								controlsList="nofullscreen nodownload"
								disablePictureInPicture
								src={update_video_url(video.video_url)}
							></video>
						</div>
					)}
			<div className={styles.animation}></div>
		</div>
	);
};

const update_video_url = (url: string): string => {
	return url.replace(
		'https://cdn.themapmakerfrombaghdad.com/view/data/state/',
		'https://video.themapmakerfrombaghdad.com/'
	);
};

export default VideoOverlay;
